import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7455ea10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table--pagination flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      background: "",
      onCurrentChange: _ctx.onSelectPage,
      layout: "prev, pager, next",
      "page-size": _ctx.query.limit,
      total: _ctx.total
    }, null, 8, ["onCurrentChange", "page-size", "total"])
  ]))
}